import '../../css/theme.css';
import Logo from '../../components/Logo';
import MenuLeft from '../../components/MenuLeft';
import ProfileHead from '../../components/ProfileHead';



import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import Driver from '../../images/d1.jpg';
import blackback from '../../images/black-back.svg';



function BookingHistory() {

    const navigate = useNavigate();


    const backtoprevstep = (e) => {
        e.preventDefault();
        navigate('/user-dashboard'); // Redire
    };

    return (

        <>
            {<Helmet>
                <link rel="stylesheet" href="/theme-profile.css" />
            </Helmet>}



            <div className="dashboard_main" >
                <div className="dashboard_head">
                    <div className="dashboard_head_in">
                        <Logo />
                        <ProfileHead />

                        <div className="clearfix"></div>

                    </div>
                </div>

                <div className="main_height_box">

                    <div className="main_height_head">
                        <div className="backtodriver_search" onClick={backtoprevstep}>
                            <img src={blackback} alt="back icon" style={{ height: '17px' }} />
                        </div>
                        <h2 className="list_headline">Booking History</h2>
                    </div>

                    <div className="main_height_box_inner main_height_box_inner_padding">
                        <div className="main_height_box_inner_div">
                            <div className="driverdetails_box">
                                <div className="driverdetails_box_in text-start">
                                    <div className="d-flex">
                                        <div>
                                            <img src={Driver} alt="" width={100} className="img-fluid driverprofilepicture" />
                                        </div>
                                        <div className="ps-3 ">
                                            <div className="d_name">Go Name 1</div>
                                            <div className="d_carname"><b>TIAGO </b> | WB26 BB 7996</div>
                                            <div className="d_carcolor">Color: White</div>
                                        </div>
                                    </div>
                                    <div className="driverdetails_box_locations">
                                        <ul>
                                            <li>
                                                <b>Pickup Locations:</b>
                                                <p>Candor Tech Space, DH Block, Action Area I, Newtown, Kolkata, West Bengal 700156, India</p>
                                            </li>
                                            <li>
                                                <b>Drop off Locations:</b>
                                                <p>Globsyn Crystals, Tower II, Level - 1, EP Block, Sector V, Kolkata, West Bengal 700091, India</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="driverdetails_box">
                                <div className="driverdetails_box_in text-start">
                                    <div className="d-flex">
                                        <div>
                                            <img src={Driver} alt="" width={100} className="img-fluid driverprofilepicture" />
                                        </div>
                                        <div className="ps-3 ">
                                            <div className="d_name">Go Name 1</div>
                                            <div className="d_carname"><b>TIAGO </b> | WB26 BB 7996</div>
                                            <div className="d_carcolor">Color: White</div>
                                        </div>
                                    </div>
                                    <div className="driverdetails_box_locations">
                                        <ul>
                                            <li>
                                                <b>Pickup Locations:</b>
                                                <p>Candor Tech Space, DH Block, Action Area I, Newtown, Kolkata, West Bengal 700156, India</p>
                                            </li>
                                            <li>
                                                <b>Drop off Locations:</b>
                                                <p>Globsyn Crystals, Tower II, Level - 1, EP Block, Sector V, Kolkata, West Bengal 700091, India</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="driverdetails_box">
                                <div className="driverdetails_box_in text-start">
                                    <div className="d-flex">
                                        <div>
                                            <img src={Driver} alt="" width={100} className="img-fluid driverprofilepicture" />
                                        </div>
                                        <div className="ps-3 ">
                                            <div className="d_name">Go Name 1</div>
                                            <div className="d_carname"><b>TIAGO </b> | WB26 BB 7996</div>
                                            <div className="d_carcolor">Color: White</div>
                                        </div>
                                    </div>
                                    <div className="driverdetails_box_locations">
                                        <ul>
                                            <li>
                                                <b>Pickup Locations:</b>
                                                <p>Candor Tech Space, DH Block, Action Area I, Newtown, Kolkata, West Bengal 700156, India</p>
                                            </li>
                                            <li>
                                                <b>Drop off Locations:</b>
                                                <p>Globsyn Crystals, Tower II, Level - 1, EP Block, Sector V, Kolkata, West Bengal 700091, India</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>




                <MenuLeft />


            </div>




        </>



    );
}

export default BookingHistory;
