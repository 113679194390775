import '../../css/theme.css';
import Logo from '../../components/Logo.js';
import FooterSignup from '../../components/FooterSignup.js';

import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


import React, { useState } from 'react';
import driveracc from '../../images/driver-login-icon-new.svg';

import { useNavigate } from 'react-router-dom';

function UserLogin() {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form validation or API call here

        // If successful, navigate to another page
        navigate('/user-dashboard'); // Redirects to the HomePage
    };

    return (

        <>


            <div className="container">
                <div className="logohead">
                    <Logo />
                </div>

                <img src={driveracc} alt="user icon" style={{ height: '70px' }} />


                <Form className="form_user_create mt-4" onSubmit={handleSubmit}  >
                    <fieldset  >




                        <Form.Group className="mb-3 phone">
                            <Form.Control id="phone" pattern="[0-9]*" maxLength="10" minLength="10" inputMode="numeric" type="tel" placeholder="PHONE NUMBER" required autoComplete="off" />
                        </Form.Group>


                        <Form.Group className="mb-3 password">
                            <Form.Control id="password" minLength="6" maxLength="10" type={showPassword ? 'text' : 'password'} placeholder="PASSWORD" required autoComplete="off" />
                            <div onClick={togglePasswordVisibility} className="passwordview">
                                {showPassword ? <span /> : <span />}
                            </div>

                        </Form.Group>


                        <Button type="submit" className="btn_submit">login</Button>

                        <p className="size14 mt-3 mb-5"><Link className="colorwhite colora" to='/forgot-password'>Forgot password?</Link>    </p>

                    </fieldset>
                </Form>


                <FooterSignup />


            </div>
        </>



    );
}

export default UserLogin;
