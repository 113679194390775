import '../../css/theme.css';
import Logo from '../../components/Logo';
import MenuLeft from '../../components/MenuLeft';
import ProfileHead from '../../components/ProfileHead';

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';


import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import checkcircle2 from '../../images/check-circle-2.svg';




function Feedback() {

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/user-dashboard');
    };



    return (

        <>
            {<Helmet>
                <link rel="stylesheet" href="/theme-profile.css" />
            </Helmet>}




            <Form className="dashboard_main" onSubmit={handleSubmit}  >
                <div className="dashboard_head">
                    <div className="dashboard_head_in">
                        <Logo />
                        <ProfileHead />

                        <div className="clearfix"></div>

                    </div>
                </div>


                <div className="confirmations_box_top">
                    <div className="confirmations_box_mid">
                        <div className="confirmations_box">
                            <img src={checkcircle2} alt="Icon" />
                            <h1 className="h1">Congratulations!</h1>
                            <h2 className="h2  mt-4"><u>Feedback Submitted Successfully!</u></h2>
                            <p className="colorwhite mt-3">Thank you for reaching out to us. We have received your concern and are currently reviewing the details.</p>

                            <Button type="submit" className="btn_submit btn_submit_choose btn_submitcall confirm_small mt-0">
                                confirm
                            </Button>
                        </div>
                    </div>
                </div>





                <MenuLeft />


            </Form>




        </>



    );
}

export default Feedback;
