import '../css/theme.css';
import Logo from '../components/Logo';



import Form from 'react-bootstrap/Form';

import { useNavigate } from 'react-router-dom';


import React, { useState } from 'react';
import lock from '../images/lock-password.svg';



function CreatePassword() {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [showPassword1, setShowPassword1] = useState(false);

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form validation or API call here

        // If successful, navigate to another page
        navigate('/password-change-confirmation'); // Redirects to the HomePage
    };


    // Validation password and confirm password

    // Set initial states for password and confirm password
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    // Handle change in password input
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    // Handle change in confirm password input
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    // Check if passwords match
    const checkPasswords = () => {
        if (password !== confirmPassword) {
            setError("Passwords do not match!");
        } else {
            setError("");
        }
    };

    // Optionally disable the submit button if passwords don't match
    const isDisabled = password === "" || confirmPassword === "" || password !== confirmPassword;




    return (

        <>




            <div className="container">
                <div className="logohead">
                    <Logo />
                </div>

                <img src={lock} alt="Forgot password" />

                <h1 className="h1">Create new passwprd</h1>
                <p className="colorwhite">Change your password for secure access</p>


                <Form className="form_user_create mt-4" onSubmit={handleSubmit} >
                    <fieldset>



                        <Form.Group className="mb-3 password">
                            <Form.Control maxLength="10" minLength="6" id="password" type={showPassword ? 'text' : 'password'} placeholder="PASSWORD" required value={password} onChange={handlePasswordChange} />
                            <div onClick={togglePasswordVisibility} className="passwordview">
                                {showPassword ? <span /> : <span />}
                            </div>

                        </Form.Group>

                        <Form.Group className="mb-3 password">
                            <Form.Control maxLength="10" minLength="6" id="password" type={showPassword1 ? 'text' : 'password'} placeholder="CONFIRM PASSWORD" required value={confirmPassword} onBlur={checkPasswords} onChange={handleConfirmPasswordChange} />
                            <div onClick={togglePasswordVisibility1} className="passwordview">
                                {showPassword1 ? <span /> : <span />}
                            </div>

                        </Form.Group>


                        {/* Error message */}
                        {error && <div className="errormsg" style={{ color: 'red' }}>{error}</div>}


                        <button type="submit" className="btn_submit" disabled={isDisabled}>update</button>



                    </fieldset>
                </Form>





            </div>
        </>



    );
}

export default CreatePassword;
