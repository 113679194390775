import '../../css/theme.css';
import Logo from '../../components/Logo';
import MenuLeft from '../../components/MenuLeft';
import ProfileHead from '../../components/ProfileHead';

import { useNavigate, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import React, { useState } from "react";


import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import blackback from '../../images/black-back.svg';
import car from '../../images/car.svg';
import closearrow from '../../images/close-arrow.svg';
import close from '../../images/close.svg';


import data from '../../components/DriverList.json';





function FindDriver() {

    const navigate = useNavigate();

    const location = useLocation();
    const { fromLocation, toLocation } = location.state || {};



    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form validation or API call here

        // Check if a driver is selected
        if (activeIndex === null) {
            setErrorMessage("Please select a driver before proceeding!");
            return; // Stop form submission
        }

        // Get the selected driver details
        const selectedDriver = posts[activeIndex];

        // If successful, navigate to another page 
        navigate('/select-driver', {
            state: {
                fromLocation,  // Pass the 'from' value
                toLocation,    // Pass the 'to' value
                driver: selectedDriver // Pass selected driver details
            }
        });
    };

    const backtoprevstep = (e) => {
        e.preventDefault();
        navigate('/user-dashboard'); // Redire
    };

    const posts = data.posts;

    // State to keep track of the active list item and selected price
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(""); // State for selected price
    const [selectedName, setSelectedName] = useState(""); // State for selected name
    const [errorMessage, setErrorMessage] = useState(""); // Error message state

    // Function to handle click and set active item
    const handleClick = (index) => {
        setActiveIndex(index); // Set the clicked item's index as active
        setSelectedPrice(posts[index].price); // Update the selected price based on clicked item
        setSelectedName(posts[index].name); // Update the selected name based on clicked item
        setErrorMessage(""); // Clear the error message when a driver is selected
    };

    // Function to close the error message
    const closeErrorMessage = () => {
        setErrorMessage("");
    };




    return (

        <>
            {<Helmet>
                <link rel="stylesheet" href="/theme-profile.css" />
            </Helmet>}

            <div className="iframe_div">
                <div className="iframe_innerdiv">
                    <iframe
                        className="iframe"
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235850.81212011125!2d88.18254112599966!3d22.535343439863773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1727282189926!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>



            <Form className="dashboard_main" onSubmit={handleSubmit}  >

                {errorMessage && (
                    <div className="error-message error_message_selectdriver">
                        <div className="error_message_selectdriver_mid">
                            <div className="error_message_selectdriver_in">
                                <div className="error_message_selectdriver_in_box">
                                    {errorMessage}

                                    <button type="button" onClick={closeErrorMessage} >
                                        <img src={close} alt="Close" />
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                )}

                <div className="dashboard_head">
                    <div className="dashboard_head_in">
                        <Logo />
                        <ProfileHead />

                        <div className="clearfix"></div>

                    </div>
                </div>

                <div className="main_height_box">


                    <div className="main_height_head">
                        <div className="backtodriver_search" onClick={backtoprevstep}>
                            <img src={blackback} alt="back icon" style={{ height: '17px' }} />
                        </div>
                        <h2 className="list_headline">Near By Driver</h2>
                    </div>

                    <div className="main_height_box_inner">
                        <div className="main_height_box_inner_div find_driver_list_boxdiv">

                            {posts.map((post, index) => (

                                <div className={`find_driver_list_box ${activeIndex === index ? "active" : ""}`} key={post.id} onClick={() => handleClick(index)}>

                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <img src={car} alt="Car" />
                                            <div className="clearfix"></div>
                                            <abbr>{post.id}</abbr>
                                        </div>
                                        <div className="col-5 text-start">
                                            <h3>{post.name}</h3>
                                            <h4>{post.time}</h4>
                                            <h5>{post.trip}</h5>
                                        </div>
                                        <div className="col-3 text-center">
                                            <div className="pricenumber">₹{post.price}</div>
                                            <del>₹{post.offer}</del>
                                        </div>
                                    </div>
                                </div>

                            ))}

                        </div>
                    </div>


                </div>

                <div className="main_height_footer">
                    <h5 class="paymentmodetext_n">Only Cash Payment Accepted</h5>
                    <Button type="submit" className="btn_submit btn_submit_choose   mt-0">
                        Choose {selectedName} | ₹{selectedPrice || '0.00'} <img src={closearrow} alt="Close" />
                    </Button>
                </div>



                <MenuLeft />


            </Form>




        </>



    );
}

export default FindDriver;
