import '../css/theme.css';
import Logo from '../components/Logo';
import FooterLogin from '../components/FooterLogin';
import { Link } from 'react-router-dom';

import driver from '../images/driver-icon.svg';
import user from '../images/user.svg';
import usericon from '../images/user-icon.svg';


function Lander() {
  return (

    <>
      <div className="container">
        <div className="logohead">
          <Logo />
        </div>

        <img src={usericon} alt="user icon" />
        <h1 className="h1">Welcome to [App Name]!</h1>
        <p className="colorwhite">Glad to have you here! Let’s get started</p>

        <div className="loginbox mt-4">


          <div className="row justify-content-center">

            <div className="col-6 col-md-4 col-lg-3">
              <Link to='/create-account' className="logindiv">
                <img src={driver} alt="driver" />
                <h2>driver</h2>
              </Link>
            </div>

            <div className="col-6  col-md-4 col-lg-3">
              <Link to='/create-account' className="logindiv">
                <img src={user} alt="user" />
                <h2>user</h2>
              </Link>
            </div>

          </div>


        </div>

        <FooterLogin />

      </div>
    </>



  );
}

export default Lander;
