import '../../css/theme.css';
import Logo from '../../components/Logo';
import MenuLeft from '../../components/MenuLeft';
import ProfileHead from '../../components/ProfileHead';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';


import drivercar from '../../images/drivercar.svg';

function BookingDriver() {

    const location = useLocation();
    const navigate = useNavigate();

    // Retrieve driver data passed from FindDriver
    const { driver } = location.state || {};
    const { fromLocation, toLocation } = location.state || {};

    const [seconds, setSeconds] = useState(0); // Timer state
    const [isWaitingVisible, setIsWaitingVisible] = useState(true); // Visibility for waiting button
    const [isOtpVisible, setIsOtpVisible] = useState(false); // Visibility for feedback button

    const [isCallBtnVisible, setIsCallBtnVisible] = useState(true); // Visibility for waiting button
    const [isOnrideVisible, setIsOnrideVisible] = useState(false); // Visibility for waiting button

    useEffect(() => {
        let timer;

        // Start timer if waiting button is visible
        if (isWaitingVisible) {
            timer = setInterval(() => {
                setSeconds((prevSeconds) => {
                    const newSeconds = prevSeconds + 1;

                    // Hide waiting button after 60 seconds
                    if (newSeconds >= 15) {
                        setIsWaitingVisible(false);
                        setIsOtpVisible(true); // Show feedback button after hiding waiting button
                    }

                    return newSeconds;
                });
            }, 1000);
        }


        return () => clearInterval(timer); // Cleanup timer on component unmount
    }, [isWaitingVisible]);

    useEffect(() => {
        // Hide feedback button after 15 seconds of visibility
        if (isOtpVisible) {
            const feedbackTimer = setTimeout(() => {
                setIsOtpVisible(false);
                setIsCallBtnVisible(false);
                setIsOnrideVisible(true);
                // After another 15 seconds, navigate to another page
                setTimeout(() => {
                    navigate('/ride-completed'); // Navigate after second 15 seconds
                }, 15000); // 15 seconds after "Ride is on"

            }, 15000); // 15 seconds

            return () => clearTimeout(feedbackTimer); // Cleanup feedback timer on component unmount
        }
    }, [isOtpVisible, navigate]);





    const backtoprevstep = (e) => {
        e.preventDefault();
        navigate('/ride-cancel-form');
    };



    return (

        <>
            {<Helmet>
                <link rel="stylesheet" href="/theme-profile.css" />
            </Helmet>}

            <div className="iframe_div">
                <div className="iframe_innerdiv">
                    <iframe
                        className="iframe"
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235850.81212011125!2d88.18254112599966!3d22.535343439863773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1727282189926!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>



            <Form className="dashboard_main" >
                <div className="dashboard_head">
                    <div className="dashboard_head_in">
                        <Logo />
                        <ProfileHead />

                        <div className="clearfix"></div>

                    </div>
                </div>

                <div className="main_height_box main_height_box_p">


                    <div className="main_height_box_inner">
                        <div className="main_height_box_inner_div">

                            <div className="">
                                <div className="after_confermations_box">
                                    {driver ? (
                                        <>
                                            <div className="driverdetails_box">
                                                <div className="driverdetails_box_in text-start">
                                                    <div className="d-flex">
                                                        <div>
                                                            <img src={driver.driverprofilepicture} alt="" width={100} className="img-fluid driverprofilepicture" />
                                                        </div>
                                                        <div className="ps-3">
                                                            <div className="d_name">{driver.name}</div>
                                                            <div className="d_carname"><b>{driver.carname}</b> | {driver.id}</div>
                                                            <div className="d_carcolor">Color: {driver.carcolor}</div>
                                                        </div>
                                                    </div>
                                                    <div className="driverdetails_box_locations">
                                                        <ul>
                                                            <li>
                                                                <b>Pickup Locations:</b>
                                                                <p>{fromLocation}</p>
                                                            </li>
                                                            <li>
                                                                <b>Drop off Locations:</b>
                                                                <p>{toLocation}</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="btn_holder">
                                                {isWaitingVisible && (
                                                    <Button type="button" className="btn_acceptance">
                                                        Waiting for Driver Acceptance... {seconds}s
                                                    </Button>
                                                )}
                                                {isOtpVisible && (
                                                    /* <Button type="button" className="btn_feedback">
                                                        Complaint / Otp
                                                    </Button> */
                                                    <div className="topshow">OTP : 8209</div>
                                                )}
                                                {isCallBtnVisible && (
                                                    <Button type="button" className="btn_cancel" onClick={backtoprevstep}>
                                                        Cancel booking
                                                    </Button>
                                                )}
                                            </div>

                                            <p className="tripdistance tripdistance_b">ID: 9987WEE</p>

                                            <p className="tripdistance tripdistance_b mt-2 tripdistance_bking">
                                                <img src={drivercar} className="me-2" alt="back icon" style={{ height: '12px' }} />
                                                {driver.id}
                                            </p>
                                            <p className="tripprice tripprice_bking"> ₹{driver.price}</p>

                                        </>
                                    ) : (
                                        <p>No driver selected.</p>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>


                </div>

                <div className="main_height_footer">
                    <h5 class="paymentmodetext_n">Only Cash Payment Accepted</h5>


                    {isCallBtnVisible && (
                        <Button type="button" className="btn_submit btn_submit_choose btn_submitcall mt-0">
                            Call driver
                        </Button>
                    )}

                    {isOnrideVisible && (
                        <Button type="button" className="btn_submit btn_submit_choose btn_submitcall   live_btn mt-0">
                            <div class="cta-ship"></div> Ride is on
                        </Button>
                    )}


                </div>



                <MenuLeft />


            </Form>




        </>



    );
}

export default BookingDriver;
