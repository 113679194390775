import '../css/theme.css';
import Logo from '../components/Logo';




import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';

import lock from '../images/lock-password.svg';
import closearrow from '../images/close-arrow.svg';

import OTPInput from '../components/OTPInput';

import { useNavigate } from 'react-router-dom';

function ForgotPassword() {



    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    const [resendTimer, setResendTimer] = useState(30); // Resend timer (seconds)
    const [canResend, setCanResend] = useState(false); // Control button availability
    const [resendMessage, setResendMessage] = useState(''); // State for resend message

    const [phone, setPhone] = useState(''); // Phone validations 
    const [errorMessage11, setErrorMessagephone] = useState(''); // Phone validations 


    useEffect(() => {


        let timer;
        // Countdown when the timer is greater than 0
        if (resendTimer > 0) {
            timer = setTimeout(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
        } else if (resendTimer === 0) {
            setCanResend(true); // Enable the resend button when the timer reaches 0
        }

        return () => clearTimeout(timer); // Cleanup timer
    }, [resendTimer]);




    const handleOtpChange = (value) => {
        setOtp(value);
        if (error) setError('');
        if (success) setSuccess(false);

    };

    const handleSubmit = () => {

        if (!phone) {
            setErrorMessagephone('Please fill in all fields.'); // Set error message
            return; // Prevent submission if any field is empty
        }
        if (!/^\d{10}$/.test(phone)) {
            setErrorMessagephone('Enter a valid 10-digit number.'); // Set error message for invalid phone
            return; // Prevent submission if phone is invalid
        }


        if (otp.length !== 6) {
            setError('Please enter a 6-digit OTP.');
        } else if (otp !== '123456') {
            setError('Incorrect OTP. Please try again.');
        } else {
            setSuccess(true);
            setError('');
            setTimeout(() => {
                navigate('/create-password'); // Redirect to the desired page after 2 seconds
            }, 2000);
        }


    };


    const handleResendOTP = () => {
        // Function to resend OTP
        setOtp('');
        setError('');
        setResendTimer(30); // Reset timer
        setCanResend(false);
        setResendMessage('OTP Re-sent. Please check your SMS.'); // Set resend message

        // Clear the resend message after 3 seconds
        setTimeout(() => {
            setResendMessage('');
        }, 3000); // 5000 milliseconds = 3 seconds
    };


    const Sendotp = (e) => {
        setOtp('');
        setError('');
        e.preventDefault();

        if (!phone) {
            setErrorMessagephone('Please fill in all fields.'); // Set error message
            return; // Prevent submission if any field is empty
        }
        if (!/^\d{10}$/.test(phone)) {
            setErrorMessagephone('Enter a valid 10-digit number.'); // Set error message for invalid phone
            return; // Prevent submission if phone is invalid
        }

        // Clear error message if all fields are filled
        setErrorMessagephone('');



        if (phone.length === 10) { // The formatted phone length (e.g., (123) 456-7890 is 14 characters)
            // Extract only digits to check if it's a valid number
            const digitsOnly = phone.replace(/\D/g, '');

            // Check if the length of digits is exactly 10
            if (digitsOnly.length === 10) {
                document.body.classList.add('phone-valid');
            } else {
                document.body.classList.remove('phone-valid');
            }
        } else {
            document.body.classList.remove('phone-valid');
        }


        // Start the resend timer when "Send OTP" is clicked
        setResendTimer(30); // Start the 30-second countdown
        setCanResend(false); // Disable resend button initially


        // Cleanup function to ensure class is removed if the component is unmounted
        return () => {
            document.body.classList.remove('phone-valid');
        };

    };




    useEffect(() => {
        if (phone.length === 0) {
            document.body.classList.remove('phone-valid');
        }
    }, [phone]);






    return (

        <>




            <div className="container">
                <div className="logohead">
                    <Logo />
                </div>


                <img src={lock} alt="user icon" />
                <h1 className="h1">Forgot your password?</h1>
                <p className="colorwhite">Don't worry, we'll help you reset it in no time!</p>


                <div className="form_user_create mt-4">

                    <Form.Group className="mb-3 phone phonesentotp">
                        <Form.Control value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" type="tel" pattern="[0-9]*" minLength="10" maxLength="10" inputMode="numeric" placeholder="PHONE NUMBER" required autoComplete="off" />

                        <button onClick={Sendotp} type="submit" className="phonesentotpsubmit"> <img src={closearrow} alt="Close" /></button>

                    </Form.Group>

                    {errorMessage11 && (
                        <div className="errormsg" style={{ marginBottom: '15px' }}>{errorMessage11}</div>
                    )}

                    <div className="otpinputforgotpassword">

                        <OTPInput className="otpinput" length={6} onChange={handleOtpChange} error={!!error} value={otp} />

                        {error && <p className="errormsg" style={{ color: 'red' }}>{error}</p>}
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <button className="colorfff btnsentotp btnresentotp "
                            onClick={handleResendOTP}
                            disabled={!canResend}
                            style={{
                                cursor: canResend ? 'pointer' : 'not-allowed',
                                backgroundColor: canResend ? 'none' : 'none',

                            }}
                        >
                            {canResend ? 'Didn’t get a code? Resend OTP' : `Resend OTP in ${resendTimer}s`}
                        </button>
                    </div>


                    <button className="btn_submit"
                        onClick={handleSubmit}
                        disabled={otp.length !== 6}
                        style={{
                            marginTop: '20px',
                            padding: '10px 20px',
                            fontSize: '16px',
                            cursor: otp.length === 6 ? 'pointer' : 'not-allowed',
                        }}
                    >
                        verify
                    </button>
                    {success && (
                        <div style={{
                            marginTop: '15px',
                            padding: '10px 15px',
                            backgroundColor: '#d4edda',
                            borderRadius: '3px',
                            color: '#155724',
                            fontSize: '14px',
                            border: '1px solid #c3e6cb'
                        }}>
                            Successfully! OTP verified.<strong> Redirecting...</strong>
                        </div>
                    )}



                    {resendMessage && ( // Conditional rendering of the resend message
                        <div style={{
                            marginTop: '20px',
                            padding: '10px',
                            backgroundColor: '#d1ecf1',
                            borderRadius: '5px',
                            color: '#0c5460',
                            fontSize: '16px',
                            border: '1px solid #bee5eb'
                        }}>
                            {resendMessage}
                        </div>
                    )}







                </div>













            </div >
        </>



    );
}

export default ForgotPassword;
