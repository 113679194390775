import '../../css/theme.css';
import Logo from '../../components/Logo';
import MenuLeft from '../../components/MenuLeft';
import ProfileHead from '../../components/ProfileHead';

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';


import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import blackback from '../../images/black-back.svg';

import React, { useState, useEffect } from "react";




function RideCancelForm() {


    const navigate = useNavigate();

    const backtoprevstep = (e) => {
        e.preventDefault();
        navigate('/user-dashboard');
    };



    const [feedback, setFeedback] = useState("");

    const [submitted, setSubmitted] = useState(false); // For showing success message

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };



    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitted(true); // Show success message
        setFeedback(""); // Clear feedback input


        navigate('/ride-cancel');
    };


    // Use useEffect to hide the success message after 15 seconds
    useEffect(() => {
        if (submitted) {
            const timer = setTimeout(() => {
                setSubmitted(false); // Hide the message after 15 seconds
            }, 5000); // 15000 milliseconds = 15 seconds

            return () => clearTimeout(timer); // Cleanup the timer on unmount or re-render
        }
    }, [submitted]);



    return (

        <>
            {<Helmet>
                <link rel="stylesheet" href="/theme-profile.css" />
            </Helmet>}

            <div className="iframe_div">
                <div className="iframe_innerdiv">
                    <iframe
                        className="iframe"
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235850.81212011125!2d88.18254112599966!3d22.535343439863773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1727282189926!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>


            <Form className="dashboard_main feedback-form" onSubmit={handleSubmit}   >
                <div className="dashboard_head">
                    <div className="dashboard_head_in">
                        <Logo />
                        <ProfileHead />

                        <div className="clearfix"></div>

                    </div>
                </div>

                <div className="footer_button footer_button_m_0">
                    <div className="find_driver_list">
                        <div className="backtodriver_search" onClick={backtoprevstep} style={{ top: '30px' }}>
                            <img src={blackback} alt="back icon" style={{ height: '17px' }} />
                        </div>


                        <h2 className="list_headline">Cancel Booking </h2>


                        <div className="feedback_box">
                            {submitted && (
                                <div className="success-message">
                                    Booking Cancel Successfully!
                                </div>
                            )}



                            <div className="form-group">
                                <label htmlFor="feedback">Enter a Description:</label>
                                <textarea
                                    id="feedback"
                                    value={feedback}
                                    onChange={handleFeedbackChange}
                                    placeholder="Enter a Description"
                                    required
                                />
                            </div>

                            <Button type="submit" className="btn_cancel">Cancel booking</Button>
                        </div>


                    </div>


                </div>



                <MenuLeft />


            </Form>




        </>



    );
}

export default RideCancelForm;
