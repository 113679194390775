import { Link } from 'react-router-dom';
import profilepic from '../images/profile-pic.svg';
import star from '../images/star.svg';
//import liprofile from '../images/li-profile.svg';
import lilocation from '../images/li-location.svg';
import liacountactive from '../images/li-acount-active.svg';
import lisuport from '../images/li-suport.svg';
import lirule from '../images/li-rule.svg';
import lifaq from '../images/li-faq.svg';
import lilogout from '../images/li-logout.svg';
import closearrow from '../images/close-arrow.svg';


function MenuLeft() {

    ///////// menu ///////////////// 
    const handlelistmenuclose = () => {
        document.body.classList.remove('menuopen')
    };
    return (
        <>
            <div className="left_side_menu">
                <div className="left_side_box">
                    <div className="closemenu" onClick={handlelistmenuclose}>
                        <img src={closearrow} alt="Close" />
                    </div>

                    <div className="menu_header">

                        <div className="d-flex align-items-center">
                            <img src={profilepic} alt="Menu Icon" className="menuprofileimg" />
                            <div>
                                <h3>Rahul Chaudhri</h3>
                                <h4>+91 9999-888-777</h4>
                                <div className="d-inline-flex starlist">
                                    <img src={star} alt="Star Icon" />
                                    <img src={star} alt="Star Icon" />
                                    <img src={star} alt="Star Icon" />
                                    <img src={star} alt="Star Icon" />
                                    <img src={star} alt="Star Icon" />
                                    <div> 5/5</div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="menu_list">
                        <ul>
                            <li><Link onClick={handlelistmenuclose} to='/user-dashboard'><img src={lilocation} alt="" />Dashboard</Link></li>
                            <li><Link onClick={handlelistmenuclose} to='/booking-history'><img src={liacountactive} alt="" />Booking History</Link></li>
                            <li><Link onClick={handlelistmenuclose} to='/user-dashboard'><img src={lisuport} alt="" />Online Support</Link></li>
                            <li><Link onClick={handlelistmenuclose} to='/user-dashboard'><img src={lirule} alt="" />Rules & terms</Link></li>
                            <li><Link onClick={handlelistmenuclose} to='/feedback-given'><img src={lisuport} alt="" />Complaint / Feedback</Link></li>
                            <li><Link onClick={handlelistmenuclose} to='/user-dashboard'><img src={lifaq} alt="" />FAQ</Link></li>
                            <li><Link onClick={handlelistmenuclose} to='/login'><img src={lilogout} alt="" />Log out</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>

    );
}

export default MenuLeft;
