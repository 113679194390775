import '../../css/theme.css';
import Logo from '../../components/Logo';
import MenuLeft from '../../components/MenuLeft';
import ProfileHead from '../../components/ProfileHead';




import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { useNavigate } from 'react-router-dom';


function UserDashboard() {


    // State to store search terms for both input fields
    const [searchTerm1, setSearchTerm1] = useState("");
    const [searchTerm2, setSearchTerm2] = useState("");

    // Timeout states to handle typing delays
    const [typingTimeout1, setTypingTimeout1] = useState(null);
    const [typingTimeout2, setTypingTimeout2] = useState(null);

    // Arrays of list items for each input field
    const items1 = ["Globsyn Crystals, Tower II, Level - 1, EP Block, Sector V, Kolkata, West Bengal 700091, India", "Candor Tech Space, DH Block, Action Area I, Newtown, Kolkata, West Bengal 700156, India", "Signet Tower, DN2, DN Block, Sector V, Salt Lake City, Kolkata, West Bengal 700091, India"];
    const items2 = ["Globsyn Crystals, Tower II, Level - 1, EP Block, Sector V, Kolkata, West Bengal 700091, India", "Candor Tech Space, DH Block, Action Area I, Newtown, Kolkata, West Bengal 700156, India", "Signet Tower, DN2, DN Block, Sector V, Salt Lake City, Kolkata, West Bengal 700091, India"];

    // Function to remove class after a delay (indicating typing is finished)
    const removeClassAfterTyping = (inputNumber) => {
        if (inputNumber === 1) {
            document.body.classList.remove('input1-has-value');
        } else if (inputNumber === 2) {
            document.body.classList.remove('input2-has-value');
        }
    };

    // Effect to add/remove class for the first input with typing delay
    useEffect(() => {

        const timeout = setTimeout(() => {
            if (searchTerm1) {
                document.body.classList.add('input1-has-value');


                // Clear previous timeout if the user is still typing
                if (typingTimeout1) clearTimeout(typingTimeout1);

                // Set a timeout to remove the class after 1 second of no typing
                const timeout = setTimeout(() => removeClassAfterTyping(1), 1000);
                setTypingTimeout1(timeout);
            } else {
                document.body.classList.remove('input1-has-value');
                document.body.classList.remove('input1-has-value-close');
            }
        },);

        // Cleanup function
        return () => clearTimeout(timeout);

    }, [searchTerm1, typingTimeout1]);

    // Effect to add/remove class for the second input with typing delay
    useEffect(() => {

        const timeout = setTimeout(() => {
            if (searchTerm2) {
                document.body.classList.add('input2-has-value');

                // Clear previous timeout if the user is still typing
                if (typingTimeout2) clearTimeout(typingTimeout2);

                // Set a timeout to remove the class after 1 second of no typing
                const timeout = setTimeout(() => removeClassAfterTyping(2), 1000);
                setTypingTimeout2(timeout);
            } else {
                document.body.classList.remove('input2-has-value');
                document.body.classList.remove('input2-has-value-close');
            }
            // Cleanup function to clear the timeout
            return () => {
                if (typingTimeout2) clearTimeout(typingTimeout2);
            };
        },);

        // Cleanup function
        return () => clearTimeout(timeout);

    }, [searchTerm2, typingTimeout2]);

    // Functions to handle input field changes for each input
    const handleInputChange1 = (e) => setSearchTerm1(e.target.value);
    const handleInputChange2 = (e) => setSearchTerm2(e.target.value);

    // Filtered lists based on search terms
    const filteredItems1 = items1.filter((item) =>
        item.toLowerCase().includes(searchTerm1.toLowerCase())
        //searchTerm1.length > 1 && item.toLowerCase().includes(searchTerm1.slice(1).toLowerCase())
    );
    const filteredItems2 = items2.filter((item) =>
        item.toLowerCase().includes(searchTerm2.toLowerCase())
        //searchTerm2.length > 1 && item.toLowerCase().includes(searchTerm2.slice(1).toLowerCase())
    );

    // Functions to handle clicks on list items for each input
    const handleClick1 = (value) => {
        setSearchTerm1(value);
        document.body.classList.add('input1-has-value-close'); // Remove class when li is clicked
    };

    const handleClick2 = (value) => {
        setSearchTerm2(value);
        document.body.classList.add('input2-has-value-close'); // Remove class when li is clicked
    };


    // Button Redirects
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form validation or API call here

        // If successful, navigate to another page
        navigate('/find-driver', {
            state: {
                fromLocation: searchTerm1,
                toLocation: searchTerm2
            }
        });

    };


    // Clear Input selectted value
    const clearInput1 = (value) => {
        setSearchTerm1(''); // Clear input value for field 1
    };

    const clearInput2 = (value) => {
        setSearchTerm2(''); // Clear input value for field 2
    };




    return (

        <>
            {<Helmet>
                <link rel="stylesheet" href="/theme-profile.css" />
            </Helmet>}

            <div className="iframe_div">
                <div className="iframe_innerdiv">
                    <iframe
                        className="iframe"
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235850.81212011125!2d88.18254112599966!3d22.535343439863773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1727282189926!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>


            <Form className="dashboard_main" onSubmit={handleSubmit}  >
                <div className="dashboard_head">
                    <div className="dashboard_head_in">
                        <Logo />
                        <ProfileHead />
                        <div className="clearfix"></div>




                        {/* First Input Field and Searchable List */}
                        <div className="search_dropdown_body search_dropdown_body1">

                            <button className="clearInput clearInput1" onClick={clearInput1}></button>

                            <input
                                required
                                className="input_dropdown form-control"
                                type="text"
                                value={searchTerm1}
                                onChange={handleInputChange1}
                                placeholder="Where from?"
                            />
                            {filteredItems1.length > 0 ? (
                                <div className="search_dropdown">
                                    <ul>
                                        {filteredItems1.map((item, index) => (
                                            <li key={index} onClick={() => handleClick1(item)}>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <p className="notfound">No items found</p>
                            )}
                        </div>

                        {/* Second Input Field and Searchable List */}
                        <div className="search_dropdown_body search_dropdown_body2">

                            <button className="clearInput clearInput2" onClick={clearInput2}> </button>

                            <input
                                required
                                className="input_dropdown form-control"
                                type="text"
                                value={searchTerm2}
                                onChange={handleInputChange2}
                                placeholder="Where to?"
                            />
                            {filteredItems2.length > 0 ? (
                                <div className="search_dropdown">
                                    <ul>
                                        {filteredItems2.map((item, index) => (
                                            <li key={index} onClick={() => handleClick2(item)}>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <p className="notfound">No items found</p>
                            )}
                        </div>





                    </div>
                </div>

                <div className="footer_button">
                    <Button type="submit" className="btn_submit">Find a driver</Button>
                </div>



                <MenuLeft />


            </Form>




        </>



    );
}

export default UserDashboard;
