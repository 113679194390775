import '../css/theme.css';
import Logo from '../components/Logo';
import FooterSignup from '../components/FooterSignup';

import { Link } from 'react-router-dom';

import driver from '../images/driver-icon.svg';
import user from '../images/user.svg';
import usericon from '../images/user-icon.svg';


function LoginLander() {
    return (

        <>
            <div className="container">
                <div className="logohead">
                    <Logo />
                </div>

                <img src={usericon} alt="user icon" />
                <h1 className="h1">Access Your Account</h1>
                <p className="colorwhite">Enter your credentials to get started</p>

                <div className="loginbox mt-4">


                    <div className="row justify-content-center">

                        <div className="col-6 col-md-4 col-lg-3">
                            <Link to='/driver-login' className="logindiv">
                                <img src={driver} alt="driver" />
                                <h2>Login</h2>
                            </Link>
                        </div>

                        <div className="col-6  col-md-4 col-lg-3">
                            <Link to='/user-login' className="logindiv">
                                <img src={user} alt="user" />
                                <h2>Login</h2>
                            </Link>
                        </div>

                    </div>


                </div>


                <FooterSignup />
            </div>
        </>



    );
}

export default LoginLander;
