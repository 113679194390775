import { useEffect, useState } from "react";
import './css/theme.css';


import Loader from "./components/Loader";

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

/* PAGE */
import Lander from './pages/Lander.js';
import Demo from './pages/Demo.js';
import Terms from './pages/Terms.js';
import Privacy from './pages/Privacy.js';
import LoginLander from './pages/Login-Lander.js';
import CreateAccountUser from './pages/user-signup/Create-Account-User.js';
import PhoneVarify from './pages/user-signup/Phone-Varify.js';
import AccountOpenConfirmation from './pages/Account-Open-Confirmation.js';
import UserLogin from './pages/user/User-Login.js';
import DriverLogin from './pages/driver/Driver-Login.js';
import ForgotPassword from './pages/Forgot-Password.js';
import CreatePassword from './pages/Create-Password.js';
import PasswordChangeConfirmation from './pages/Password-Change-Confirmation.js';
import UserDashboard from './pages/user/User-Dashboard.js';
import FindDriver from './pages/user/Find-Driver.js';
import SelectDriver from './pages/user/Select-Driver.js';
import BookingDriver from './pages/user/Booking-Driver.js';
import RideCompleted from './pages/user/Ride-Completed.js';
import Feedback from './pages/user/Feedback.js';
import RideCancel from './pages/user/Ride-Cancel.js';
import FeedbackGiven from './pages/user/Feedback-Given.js';
import RideCancelForm from './pages/user/Ride-Cancel-Form.js';
import BookingHistory from './pages/user/Booking-History.js';

function App() {
  // loader state
  const [isLoading, setIsLoading] = useState(true);

  // Let create async method to fetch fake data
  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    fakeDataFetch();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="mainpage">
      <div className="pagemain">
        <div className="pageinner">
          <div className="pagemid">
            <div className="sectionload">


              <Router>


                <Routes>
                  <Route exact path='/' element={<Lander />} />
                  <Route exact path='/demo' element={<Demo />} />
                  <Route exact path='/terms-of-service' element={<Terms />} />
                  <Route exact path='/privacy-policy' element={<Privacy />} />
                  <Route exact path='/login' element={<LoginLander />} />
                  <Route exact path='/create-account' element={<CreateAccountUser />} />
                  <Route exact path='/phone-varify' element={<PhoneVarify />} />
                  <Route exact path='/account-open-confirmation' element={<AccountOpenConfirmation />} />
                  <Route exact path='/user-login' element={<UserLogin />} />
                  <Route exact path='/driver-login' element={<DriverLogin />} />
                  <Route exact path='/forgot-password' element={<ForgotPassword />} />
                  <Route exact path='/Create-password' element={<CreatePassword />} />
                  <Route exact path='/password-change-confirmation' element={<PasswordChangeConfirmation />} />
                  <Route exact path='/user-dashboard' element={<UserDashboard />} />
                  <Route exact path='/find-driver' element={<FindDriver />} />
                  <Route exact path='/select-driver' element={<SelectDriver />} />
                  <Route exact path='/booking-selcet-driver' element={<BookingDriver />} />
                  <Route exact path='/ride-completed' element={<RideCompleted />} />
                  <Route exact path='/feedback' element={<Feedback />} />
                  <Route exact path='/ride-cancel' element={<RideCancel />} />
                  <Route exact path='/feedback-given' element={<FeedbackGiven />} />
                  <Route exact path='/ride-cancel-form' element={<RideCancelForm />} />
                  <Route exact path='/booking-history' element={<BookingHistory />} />

                  {/* Catch-all route for undefined routes */}
                  <Route path="*" element={<Lander />} />

                  {/* Redirect to home if no match found */}
                  <Route path="*" element={<Navigate to="/" />} />

                  {/* OR - Render a custom 404 Page */}
                  {/* <Route path="*" element={<NotFoundPage />} /> */}

                </Routes>

              </Router>



            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;
