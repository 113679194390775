import '../../css/theme.css';
import Logo from '../../components/Logo';
import MenuLeft from '../../components/MenuLeft';
import ProfileHead from '../../components/ProfileHead';

import { useNavigate, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';


import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import blackback from '../../images/black-back.svg';

import closearrow from '../../images/close-arrow.svg';


function SelectDriver() {

    const location = useLocation();

    // Retrieve the driver data passed from FindDriver
    const { driver } = location.state || {}; // Using optional chaining to avoid errors
    const { fromLocation, toLocation } = location.state || {};


    const navigate = useNavigate();

    const backtoprevstep = (e) => {
        e.preventDefault();
        navigate('/user-dashboard');
    };


    const bookingconfirm = (e) => {
        e.preventDefault();

        navigate('/booking-selcet-driver', {
            state: {
                fromLocation,  // Pass the 'from' value
                toLocation,    // Pass the 'to' value
                driver // Pass selected driver details
            }
        });
    };




    return (

        <>
            {<Helmet>
                <link rel="stylesheet" href="/theme-profile.css" />
            </Helmet>}

            <div className="iframe_div">
                <div className="iframe_innerdiv">
                    <iframe
                        className="iframe"
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235850.81212011125!2d88.18254112599966!3d22.535343439863773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1727282189926!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>



            <Form className="dashboard_main" >
                <div className="dashboard_head">
                    <div className="dashboard_head_in">
                        <Logo />
                        <ProfileHead />

                        <div className="clearfix"></div>

                    </div>
                </div>

                <div className="main_height_box">


                    <div className="main_height_head">
                        <div className="backtodriver_search" onClick={backtoprevstep}>
                            <img src={blackback} alt="back icon" style={{ height: '17px' }} />
                        </div>
                        <h2 className="list_headline">Confirm your booking</h2>
                    </div>

                    <div className="main_height_box_inner">
                        <div className="main_height_box_inner_div">

                            <div className="">

                                {driver ? (
                                    <>
                                        <div className="driverdetails_box">
                                            <div className="driverdetails_box_in text-start">
                                                <div className="d-flex">
                                                    <div>
                                                        <img src={driver.driverprofilepicture} alt="" width={100} className="img-fluid driverprofilepicture" />
                                                    </div>
                                                    <div className="ps-3 ">
                                                        <div className="d_name">{driver.name}</div>
                                                        <div className="d_carname"><b>{driver.carname}</b> | {driver.id}</div>
                                                        <div className="d_carcolor">Color: {driver.carcolor}</div>
                                                    </div>
                                                </div>
                                                <div className="driverdetails_box_locations">
                                                    <ul>
                                                        <li>
                                                            <b>Pickup Locations:</b>
                                                            <p>{fromLocation}</p>
                                                        </li>
                                                        <li>
                                                            <b>Drop off Locations:</b>
                                                            <p>{toLocation}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        <p className="tripprice"> ₹{driver.price}</p>
                                        <p className="tripdistance">Distance: {driver.trip}</p>
                                        <p className="estimatedtime">Estimated Pickup Time: {driver.time}</p>

                                    </>
                                ) : (
                                    <p>No driver selected.</p>
                                    //<p>Selected Driver: {driver ? driver.name : 'No driver selected'}</p>
                                )}
                            </div>

                        </div>
                    </div>


                </div>

                <div className="main_height_footer">
                    <h5 class="paymentmodetext_n">Only Cash Payment Accepted</h5>
                    <Button type="submit" className="btn_submit btn_submit_choose mt-0" onClick={bookingconfirm}>
                        confirm booking <img src={closearrow} alt="Close" />
                    </Button>
                </div>



                <MenuLeft />


            </Form>




        </>



    );
}

export default SelectDriver;
