
import menuicon from '../images/menu-icon.svg';
import profilepic from '../images/profile-pic.svg';

function ProfileHead() {

    ///////// menu /////////////////
    const handlelistClick = () => {
        document.body.classList.add('menuopen')
    };
    return (
        <>
            <div className="profile_menu" onClick={handlelistClick} ><img src={menuicon} alt="Menu Icon" /></div>
            <div className="profile_pic"><img src={profilepic} alt="Profile Img" className="img-fluid" /></div>

        </>

    );
}

export default ProfileHead;
