import '../../css/theme.css';
import Logo from '../../components/Logo';



import React, { useState, useEffect } from 'react';

import phoneverify from '../../images/phone-verify.svg';

import OTPInput from '../../components/OTPInput';

import { useNavigate } from 'react-router-dom';



function PhoneVarify() {



    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    const [resendTimer, setResendTimer] = useState(30); // Resend timer (seconds)
    const [canResend, setCanResend] = useState(false); // Control button availability
    const [resendMessage, setResendMessage] = useState(''); // State for resend message

    useEffect(() => {
        let timer;
        // Start the countdown only if resendTimer is greater than 0
        if (resendTimer > 0 && !canResend) {
            timer = setTimeout(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
        } else if (resendTimer === 0) {
            setCanResend(true); // Enable the button when timer reaches 0
        }

        return () => clearTimeout(timer); // Cleanup on unmount or when timer changes
    }, [resendTimer, canResend]);

    const handleOtpChange = (value) => {
        setOtp(value);
        if (error) setError('');
        if (success) setSuccess(false);

    };

    const handleSubmit = () => {
        if (otp.length !== 6) {
            setError('Please enter a 6-digit OTP.');
        } else if (otp !== '123456') {
            setError('Incorrect OTP. Please try again.');
        } else {
            setSuccess(true);
            setError('');
            setTimeout(() => {
                navigate('/account-open-confirmation'); // Redirect to the desired page after 2 seconds
            }, 2000);
        }
    };


    const handleResendOTP = () => {
        // Function to resend OTP
        setOtp('');
        setError('');
        setResendTimer(30); // Reset timer
        setCanResend(false);
        setResendMessage('OTP Re-sent. Please check your SMS.'); // Set resend message

        // Clear the resend message after 3 seconds
        setTimeout(() => {
            setResendMessage('');
        }, 3000); // 5000 milliseconds = 3 seconds
    };

    return (

        <>




            <div className="container">
                <div className="logohead">
                    <Logo />
                </div>


                <img src={phoneverify} alt="user icon" />
                <h1 className="h1">Phone Verification</h1>
                <p className="colorwhite">Please check your SMS. We just sent a  <br />
                    verification code on your phone.<br />
                    <b>+91 9999-888-777</b></p>


                <div className="form_user_create mt-4">

                    <OTPInput className="otpinput" length={6} onChange={handleOtpChange} error={!!error} />
                    {error && <p className="errormsg" style={{ color: 'red' }}>{error}</p>}
                    <button className="btn_submit"
                        onClick={handleSubmit}
                        disabled={otp.length !== 6}
                        style={{
                            marginTop: '20px',
                            padding: '10px 20px',
                            fontSize: '16px',
                            cursor: otp.length === 6 ? 'pointer' : 'not-allowed',
                        }}
                    >
                        verify
                    </button>
                    {success && (
                        <div style={{
                            marginTop: '15px',
                            padding: '10px 15px',
                            backgroundColor: '#d4edda',
                            borderRadius: '3px',
                            color: '#155724',
                            fontSize: '14px',
                            border: '1px solid #c3e6cb'
                        }}>
                            Successfully! OTP verified.<strong> Redirecting...</strong>
                        </div>
                    )}



                    {resendMessage && ( // Conditional rendering of the resend message
                        <div style={{
                            marginTop: '20px',
                            padding: '10px',
                            backgroundColor: '#d1ecf1',
                            borderRadius: '5px',
                            color: '#0c5460',
                            fontSize: '16px',
                            border: '1px solid #bee5eb'
                        }}>
                            {resendMessage}
                        </div>
                    )}


                    <div style={{ marginTop: '20px' }}>
                        <button className="colorfff btnsentotp  "
                            onClick={handleResendOTP}
                            disabled={!canResend}
                            style={{
                                cursor: canResend ? 'pointer' : 'not-allowed',
                                backgroundColor: canResend ? 'none' : 'none',

                            }}
                        >
                            {canResend ? 'Didn’t get a code? Resend OTP' : `Resend OTP in ${resendTimer}s`}
                        </button>
                    </div>
                </div>













            </div >
        </>



    );
}

export default PhoneVarify;
