import React from "react";
import Logo from './Logo';


const Loader = () => {
    return (
        <div className="loader">
            <div className="svg-wrapper">
                <Logo />
            </div>
        </div>
    );
};

export default Loader;