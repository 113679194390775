import logo from '../images/logo.svg';

function Logo() {
    return (
        <>
            <img src={logo} className="loadlogo" alt="logo" />
        </>

    );
}

export default Logo;
