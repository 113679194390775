import { Link } from 'react-router-dom';

function FooterLogin() {
    return (
        <>
            <div className="footerhead">
                <p className="size14"> Already have an account?  <Link to='/login'>Log in here</Link>    </p>
                <p className=" mb-0">By signing up, you agree to our <br />
                    <Link to='/terms-of-service'>Terms of Service</Link> & <Link to='/privacy-policy'>Privacy Policy</Link></p>
            </div>
        </>

    );
}

export default FooterLogin;
