import React, { useState, useRef, useEffect } from 'react';

const OTPInput = ({ length = 6, onChange, error, value }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));
  const inputRefs = useRef([]);

  useEffect(() => {
    // Focus on the first input field when the component mounts
    inputRefs.current[0].focus();
  }, []);

  // Sync with parent value
  useEffect(() => {
    if (value === '') {
      setOtp(new Array(length).fill(''));
    }
  }, [value, length]);

  const handleChange = (value, index) => {
    // Allow only numeric input
    if (!/^\d$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onChange(newOtp.join(''));

    // Move focus to the next input
    if (index < length - 1 && value) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      // Clear current value and move focus to the previous input if empty
      if (otp[index] === '') {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
        onChange(newOtp.join(''));
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === 'ArrowRight' && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, length).split('');
    if (pasteData.every((char) => /^\d$/.test(char))) {
      setOtp(pasteData);
      onChange(pasteData.join(''));
      // Focus on the last filled input
      inputRefs.current[pasteData.length - 1].focus();
    }
    e.preventDefault();
  };

  return (
    <div className="otpinput" style={{ display: 'flex', justifyContent: 'center' }} onPaste={handlePaste}>
      {otp.map((data, index) => (
        <input
          key={index}
          type="tel"
          maxLength="1"
          inputMode="numeric"
          pattern="[0-9]*"
          value={data}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          style={{
            width: '51.7px',
            height: '40px',
            margin: '0 5px',
            textAlign: 'center',
            fontSize: '20px',
            border: `1px solid ${error ? 'red' : '#fff'}`,
            borderRadius: '4px',
            backgroundColor: error ? '#ffe6e6' : '#fff',
          }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
