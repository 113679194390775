import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import '../../css/theme.css';
import Logo from '../../components/Logo';
import FooterLogin from '../../components/FooterLogin';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function CreateAccountUser() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form validation or API call here
        navigate('/phone-varify'); // Redirects to the Phone Verification page
    };

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="/theme-height.css" />
            </Helmet>

            <div className="container">
                <div className="logohead">
                    <Logo />
                </div>

                <h1 className="h1">Create Your Account</h1>
                <p className="colorwhite">Join our app & unlock amazing features!</p>

                <Form className="form_user_create" onSubmit={handleSubmit}>
                    <fieldset>
                        <Form.Group className="mb-3 selectusertype">
                            <Form.Select id="selectusertype" required>
                                <option value="">Select User Type</option>
                                <option value="driver">DRIVER</option>
                                <option value="user">USER</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3 Name">
                            <Form.Control
                                id="name"
                                type="text"
                                minLength="6"
                                maxLength="25"
                                placeholder="NAME"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 phone">
                            <Form.Control
                                id="phone"
                                pattern="[0-9]*"
                                minLength="6"
                                maxLength="10"
                                inputMode="numeric"
                                type="tel"
                                placeholder="PHONE NUMBER"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 email">
                            <Form.Control
                                id="email"
                                type="email"
                                placeholder="EMAIL"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 password">
                            <Form.Control
                                maxLength="10"
                                minLength="6"
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="PASSWORD"
                                required
                            />
                            <div onClick={togglePasswordVisibility} className="passwordview">
                                {showPassword ? <span>Hide</span> : <span>Show</span>}
                            </div>
                        </Form.Group>

                        <Button type="submit" className="btn_submit">
                            Create Your Account
                        </Button>
                    </fieldset>
                </Form>

                <FooterLogin />
            </div>
        </>
    );
}

export default CreateAccountUser;
