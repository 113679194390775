import '../css/theme.css';
import Logo from '../components/Logo';

import { Link } from 'react-router-dom';

import driver from '../images/driver-icon.svg';
import user from '../images/user.svg';
import checkcircle from '../images/check-circle.svg';


function PasswordChangeConfirmation() {
    return (

        <>
            <div className="container">
                <div className="logohead">
                    <Logo />
                </div>

                <img src={checkcircle} alt="user icon" />
                <h1 className="h1">Congratulations! </h1>
                <p className="colorwhite mt-2"><b><u>Password Updated Successfully</u></b></p>

                <h2 className="h1 mt-5">Login Your Account</h2>

                <div className="loginbox mt-4">


                    <div className="row justify-content-center">

                        <div className="col-6 col-md-4 col-lg-3">
                            <Link to='/driver-login' className="logindiv">
                                <img src={driver} alt="driver" />
                                <h2>driver</h2>
                            </Link>
                        </div>

                        <div className="col-6  col-md-4 col-lg-3">
                            <Link to='/user-login' className="logindiv">
                                <img src={user} alt="user" />
                                <h2>user</h2>
                            </Link>
                        </div>

                    </div>


                </div>


            </div >
        </>



    );
}

export default PasswordChangeConfirmation;
